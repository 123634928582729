<template>
    <el-menu id="sidebar" :default-active="$route.path" background-color="#ffffff" text-color="#000000"
        active-text-color="#ffffff" :router="true" :unique-opened="true">
        <el-menu-item index="introduce">
            <span slot="title">{{ $t('main.介绍') }}</span>
        </el-menu-item>
        <el-submenu index="1">
            <template slot="title">
                <span>{{ $t('main.系统框架')}}</span>
            </template>
            <el-menu-item-group>
                <el-menu-item index="accounts" style="text-align: center;" :class="$route.name=='accounts'?'item':''">{{ $t('main.账户体系')}}</el-menu-item>
                <el-menu-item index="software" style="text-align: center;" :class="$route.name=='software'?'item':''">{{ $t('main.软件系统')}}</el-menu-item>
                <el-menu-item index="hardware" style="text-align: center;" :class="$route.name=='hardware'?'item':''">{{ $t('main.硬件设备')}}</el-menu-item>
            </el-menu-item-group>          
        </el-submenu>
        <el-submenu index="2">
            <template slot="title">
                <span>{{ $t('main.快速学习')}}</span>
            </template>
            <el-menu-item-group>
                <el-menu-item index="createHotel" style="text-align: center;" :class="$route.name=='createHotel'?'item':''">{{ $t('main.创建酒店')}}</el-menu-item>
                <el-menu-item index="checkIn" style="text-align: center;" :class="$route.name=='checkIn'?'item':''">{{ $t('main.入住办理')}}</el-menu-item>
                <el-menu-item index="staffManagement" style="text-align: center;" :class="$route.name=='staffManagement'?'item':''">{{ $t('main.员工管理')}}</el-menu-item>
                <el-menu-item index="valueAdded" style="text-align: center;" :class="$route.name=='valueAdded'?'item':''">{{ $t('main.增值服务')}}</el-menu-item>
                <el-menu-item index="statistics" style="text-align: center;" :class="$route.name=='statistics'?'item':''">{{ $t('main.数据统计')}}</el-menu-item>
                <el-menu-item index="guestGuide" style="text-align: center;" :class="$route.name=='guestGuide'?'item':''">{{ $t('main.宾客指南')}}</el-menu-item>
            </el-menu-item-group>          
        </el-submenu>
        <el-submenu index="3">
            <template slot="title">
                <span>{{ $t('main.常见问题')}}</span>
            </template>
            <el-menu-item-group>
                <el-menu-item index="cardProblem" style="text-align: center;" :class="$route.name=='cardProblem'?'item':''">{{ $t('main.发卡问题')}}</el-menu-item>
                <el-menu-item index="checkInProblem" style="text-align: center;" :class="$route.name=='checkInProblem'?'item':''">{{ $t('main.办理入住')}}</el-menu-item>
                <el-menu-item index="roomCardProblem" style="text-align: center;" :class="$route.name=='roomCardProblem'?'item':''">{{ $t('main.房卡管理')}}</el-menu-item>
                <el-menu-item index="deviceProblem" style="text-align: center;" :class="$route.name=='deviceProblem'?'item':''">{{ $t('main.设备')}}</el-menu-item>
                <el-menu-item index="lockProblem" style="text-align: center;" :class="$route.name=='lockProblem'?'item':''">{{ $t('智能锁')}}</el-menu-item>
            </el-menu-item-group>          
        </el-submenu>
        <!-- <el-menu-item index="install">
            <span slot="title">{{ $t('main.安装指南') }}</span>
        </el-menu-item>
        <el-menu-item index="quick">
            <span slot="title">{{ $t('main.快速开店') }}</span>
        </el-menu-item>
        <el-menu-item index="operate">
            <span slot="title">{{ $t('main.日常操作') }}</span>
        </el-menu-item>
        <el-menu-item index="yunying">
            <span slot="title">{{ $t('main.运营管理') }}</span>
        </el-menu-item><el-menu-item index="question">
            <span slot="title">{{ $t('main.常见问题') }}</span>
        </el-menu-item> -->
    </el-menu>
</template>

<script>
export default {
    data(){
      return {
        position:'systemFunction'
      }
    },
}
</script>

<style lang="scss">
.el-menu {
    border-right: none;
    height: 100%;
}
#sidebar {
    // padding-top: 1.82291666vw;
    // padding-right: 0.83333333vw;

    span {
        padding-left: 3.38541666vw;
        font-size: 0.9375vw;
    }

    .text {
        padding-left: 4.94791666vw;
        font-size: 0.9375vw;
    }

    .el-submenu__title {
        height: 2.39583333vw !important;
        line-height: 2.39583333vw !important;

        i {
            font-size: 1.14583333vw;
        }

        .el-submenu__icon-arrow {
            top: 44%;
        }
    }

    .el-submenu__title:hover {
        background-color: rgba(255, 241, 229, 1) !important;
        // border-radius: 0.52083333vw;
    }

    .el-menu-item {
        height: 2.39583333vw !important;
        line-height: 2.39583333vw !important;
    }

    .el-menu-item:hover {
        background-color: rgba(255, 241, 229, 1) !important;
        // border-radius: 0.52083333vw;
    }

    .is-active {
        color: #000000 !important;
        background-color: #efefef !important;
        border-radius: 0.52083333vw;
    }
    
}
.item{
    background-color: rgba(255, 241, 229, 1)  !important;
    color: rgba(254, 147, 1, 1);
    border-right: 6px solid #FA8F37 ;
}
</style>